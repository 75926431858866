<template>
  <div class="facilityBox">
    <!-- 步骤条 -->
    <div class="step">
      <div class="title">运单信息进度</div>
      <!-- 步骤条 -->
      <Step class="step" :activeList="[1, 1, 1, 0]"></Step>
      <!-- 合计 -->
      <div class="settleBox">
        <el-row>
          <el-col :span="5"
            >开票申请时间：<strong>{{
              waybillForm.applyDateTime
            }}</strong></el-col
          >
          <el-col :span="5"
            >包含运单数量(条)：<strong>{{
              waybillForm.wayBillCount
            }}</strong></el-col
          >
          <el-col :span="5"
            >{{
              getWaybillType.sceneId == 7 ? "总工单费(元)：" : "总运费(元)："
            }}：<strong>{{
              waybillForm.transFee | formatMoney
            }}</strong></el-col
          >
          <el-col :span="9"
            >总税费(元)：<strong>{{
              waybillForm.taxes | formatMoney
            }}</strong></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="5"
            >支付总金额(元)：<strong>{{
              waybillForm.totalAmount | formatMoney
            }}</strong></el-col
          >
          <el-col :span="5"
            >货主单位：<strong>{{
              serverDetail.bossEnterpriseFullName
            }}</strong></el-col
          >
          <el-col :span="5"
            >货主税号：<strong>{{
              serverDetail.bossEnterpriseCreditCode
            }}</strong></el-col
          >
          <el-col :span="9"
            >货主地址：<strong>{{
              serverDetail.bossEnterpriseAddress
            }}</strong></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="5"
            >货主电话：<strong>{{
              serverDetail.bossLegalPersonlPhone
            }}</strong></el-col
          >
          <el-col :span="5"
            >货主账号：<strong>{{
              serverDetail.bossBankerNumber
            }}</strong></el-col
          >
          <el-col :span="9"
            >货主开户行：<strong>{{
              serverDetail.bossBankerName
            }}</strong></el-col
          >
        </el-row>
      </div>
      <!-- 表单区域 -->
      <el-form
        class="waybillForm"
        :model="waybillForm"
        ref="waybillForm"
        label-width="120px"
      >
        <!-- 已选状态 -->
        <el-form-item label="已选状态" prop="address">
          <el-input v-model="waybillForm.address" disabled></el-input>
        </el-form-item>
        <!-- 已选服务商 -->
        <el-form-item label="已选服务商" prop="provider">
          <el-input v-model="waybillForm.provider" disabled></el-input>
        </el-form-item>
        <!-- 已选发票抬头 -->
        <el-form-item label="已选发票抬头" prop="invoiceTitle">
          <el-input v-model="waybillForm.invoiceTitle" disabled></el-input>
        </el-form-item>
        <!-- 已选发票类型 -->
        <el-form-item
          label="已选发票类型"
          prop="invoiceType"
          v-if="getWaybillType.sceneId != 3"
        >
          <el-input v-model="waybillForm.invoiceType" disabled></el-input>
        </el-form-item>
        <!-- 开票金额 -->
        <!-- <el-form-item label="开票金额" prop="invoiceAmoutType">
          <el-input v-model="waybillForm.invoiceAmoutType" disabled></el-input>
        </el-form-item> -->
        <!-- 专票普票 -->
        <el-form-item label="已选专票普票" prop="" style="width: 380px">
          <el-input v-model="invoiceKindValue" disabled></el-input>
        </el-form-item>
        <!-- 已选余额 -->
        <el-form-item label="余额" prop="balance">
          <el-input v-model="waybillForm.balance" disabled></el-input>
        </el-form-item>
      </el-form>
      <!-- 服务商答复状态 -->
      <div class="wait" v-if="taskType == 0">
        <el-dialog
          :visible.sync="flag.waitDialog"
          width="500px"
          @close="$router.go(-1)"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          center
        >
          <p
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px;
              color: #000000;
              font-weight: bold;
            "
          >
            <i
              class="el-icon-warning"
              style="font-size: 24px; color: #e6a23c"
            />服务商审核开票信息中，请稍后...
          </p>
          <div slot="footer" style="text-align: center">
            <el-button @click="handleClickGiveIpPayMent">放弃付款</el-button>
            <el-button type="primary" @click="waitPatiently"
              >耐心等待</el-button
            >
          </div>
        </el-dialog>
      </div>
      <!-- 已同意 -->
      <div class="allow" v-if="taskType == 1">
        <el-dialog
          :visible.sync="flag.allowDialog"
          width="700px"
          top="13vh"
          @close="$router.go(-1)"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          center
        >
          <span style="font-weight: 600" slot="title">
            <p style="font-size: 18px; display: flex; align-items: center">
              <i
                class="el-icon-success"
                style="font-size: 22px; color: #00e855; margin-right: 8px"
              />服务商已确认可以提供服务
            </p>
          </span>
          <div>
            <p style="color: #333">
              货主点击
              <span style="color: #0f5fff">【确认付款】</span
              >，将从您的虚拟账户扣除运单待支付总金额。付款成功后，等待服务商开票。
            </p>
          </div>

          <div class="allow-info" style="display: flex">
            <p>
              <span class="info-title">服务商单位：</span>
              <span class="info-content">{{
                serverDetail.enterpriseFullName
              }}</span>
            </p>
            <p>
              <span class="info-title">账户余额：</span>
              <span
                class="info-content"
                style="color: #ff8b17; font-weight: 600"
                >{{ waybillForm.balance | formatMoney }}</span
              >
            </p>
          </div>
          <div class="allow-info" style="display: flex">
            <p>
              <span class="info-title">发票类型：</span>
              <span class="info-content">{{ waybillForm.invoiceType }}</span>
            </p>

            <p>
              <span class="info-title">待支付总金额：</span
              ><span
                class="info-content"
                style="color: #ff8b17; font-weight: 600"
                >{{ serverDetail.waitPay | formatMoney }}</span
              >
            </p>
          </div>
          <div class="allow-info" style="display: flex">
            <p>
              <span class="info-title">支付方式：</span>
              <span
                class="info-content"
                style="color: #ff8b17; font-weight: 600"
                >{{ serverDetail.payDict }}</span
              >
            </p>
            <p>
              <span class="info-title">在途金额：</span>
              <span
                class="info-content"
                style="color: #ff8b17; font-weight: 600"
                >{{ waybillForm.routeAmount | formatMoney }}</span
              >
            </p>
          </div>
          <div class="allow-info" style="display: flex">
            <p v-if="waybillForm.invoiceKindTec">
              <span class="info-title">专票普票：</span>
              <span class="info-content"
                >运费-{{ waybillForm.invoiceKind }} 技术服务费-{{
                  waybillForm.invoiceKindTec
                }}
              </span>
            </p>
            <p v-else>
              <span class="info-title">专票普票：</span>
              <span class="info-content">{{ waybillForm.invoiceKind }}</span>
            </p>
            <p>
              <span class="info-title">可交易金额：</span
              ><span
                class="info-content"
                style="color: #ff8b17; font-weight: 600"
                >{{ waybillForm.dealAmount | formatMoney }}</span
              >
            </p>
          </div>
          <div class="allow-info" style="display: flex">
            <p>
              <span class="info-title">发票费率：</span
              ><span
                class="info-content"
                style="color: #ff8b17; font-weight: 600"
                >{{ serverDetail.type }}%</span
              >
            </p>
            <!-- <p>
              <span class="info-title">开票金额：</span>
              <span class="info-content">{{
                waybillForm.invoiceAmoutType
              }}</span>
            </p> -->
          </div>

          <div class="line"></div>

          <UploadFile
            style="margin-top: 8px"
            v-if="serverDetail.showOfflineVoucher"
            ref="uploadFile"
            :hideUploadFileBtn="hideUploadFileBtn"
            @showComfirmPay="showComfirmPay"
          ></UploadFile>

          <div
            style="
              text-align: center;
              display: flex;
              justify-content: flex-end;
              margin-top: 16px;
            "
          >
            <el-button @click="handleClickGiveIpPayMent">放弃付款</el-button>

            <el-button
              :disabled="flag.isComfirmPay"
              type="primary"
              @click="comfirmPays"
              :loading="flag.comfirmPaying"
            >确认付款</el-button>
            
           
          </div>
          <p style="padding: 5px 0"></p>
        </el-dialog>
      </div>
      <!-- 已拒绝 -->
      <div class="reject" v-if="taskType == 2">
        <el-dialog
          :visible.sync="flag.rejectDialog"
          width="700px"
          @close="$router.go(-1)"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          center
        >
          <div style="text-align: center">
            <i class="el-icon-error" style="color: red; font-size: 26px"></i>
          </div>
          <h1 style="font-size: 18px; text-align: center; margin-top: 8px">
            服务商无法提供服务
          </h1>
          <p style="text-align: left">
            服务商“{{
              rejectName
            }}”已拒绝您的结算申请。您可以点击【重新选择】，更换服务商
          </p>
          <hr />
          <p style="text-align: left">驳回意见：</p>
          <div
            style="border: 1px solid #606266; border-radius: 5px; padding: 8px"
          >
            <p style="text-align: left" v-if="!rejectContent">暂无</p>
            <p
              style="text-align: left"
              v-for="item in rejectContent"
              :key="item"
            >
              {{ item }}
            </p>
          </div>
          <div style="text-align: center; margin-top: 8px">
            <el-button size="mini" @click="flag.rejectDialog = false"
              >取消</el-button
            >
            <el-button type="primary" size="mini" @click="reselect"
              >重新选择</el-button
            >
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPaymentInfo,
  comfirmPays,
  billsCount,
  returnBillsInfoDeatils,
  giveUpPayMent,
  getTaskRejectOpinion,
} from "@/api/waybill/waybillManage/index";
import { mapGetters, mapMutations } from "vuex";
import Step from "@/components/businessCmpt/step";
import UploadFile from "@/components/uploadFile/uploadFile";
export default {
  data() {
    return {
      flag: {
        waitDialog: false, //等待开票dialog
        allowDialog: false, //同意开票dialog
        rejectDialog: false, //拒绝开票dialog
        showGiveUpPayMent: false, //放弃付款dialog
        comfirmPaying: false, //确认付款中
        isComfirmPay: false, // 是否可以选中 确认付款 按钮
      },
      taskType: 0, //订单状态 0等待中 1同意 2拒绝 4已开票
      waybillForm: {
        address: "", //已选状态
        provider: "", //已选服务商
        invoiceTitle: "", //已选发票抬头
        invoiceType: "", //发票类型
        invoiceKind: "", //普票专票
        invoiceKindTec: "", //技术服务费普票专票
        applyDateTime: "", //申请时间
        wayBillCount: "", //运单数量
        transFee: "", //总运费
        taxes: "", //总税费
        totalAmount: "", //支付总金额
        balance: "", //账户余额
        dealAmount: "", //可交易金额
        routeAmount: "", //在途金额
        payType: "", //支付方式
        invoiceAmoutType: "", //开票金额
      },
      invoiceTaskID: "", //开票单号
      serverDetail: {
        enterpriseFullName: "", //开票服务商
        userFound: "", //余额
        address: "", //服务商归属
        waitPay: "", //待支付金额
        status: "", //虚拟账户状态
        rate: "", //税点
        bossEnterpriseFullName: "", //货主单位
        bossEnterpriseCreditCode: "", //货主税号
        bossEnterpriseAddress: "", //货主地址
        bossBankerName: "", //开户行
        bossBankerNumber: "", //货主账号
        bossLegalPersonlPhone: "", //货主手机号
        dict: {
          list: [],
          value: "",
        }, //委托代征支付方式
      }, //服务详情
      rejectContent: "", //被拒绝的原因
      rejectName: "", //被这个服务商拒绝
      invoiceKindValue: "",
      hideUploadFileBtn: true, // 隐藏上传线下付款凭证组件中的保存按钮
    };
  },
  methods: {
    ...mapMutations(["setInvoiceTaskID"]),
    // 返回
    goBack() {
      if (this.$route.query.activeCmpt) {
        this.$router.push(
          `/waybill/pendingManage/index?activeCmpt=${this.$route.query.activeCmpt}`
        );
      } else {
        this.$router.push(`/waybill/pendingManage/index`);
      }
    },
    //耐心等待
    waitPatiently() {
      this.flag.waitDialog = false;
      this.goBack();
    },
    //重新选择
    reselect() {
      const loading = this.$loading({
        lock: true,
        text: "请稍等...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        PageSize: 100000,
        PageIndex: 1, //这么写是为了分页在前端做
        InvoiceTaskID: this.invoiceTaskID,
      };
      returnBillsInfoDeatils({ json: JSON.stringify(params) })
        .then((res) => {
          let waybillList = res.invoices.map((item) => {
            item.TaskType = item.waybillType;
            return item;
          });
          this.$emit("setWaybillList", waybillList);
          this.$emit("stepChange", 2);
        })
        .finally(() => {
          loading.close();
        });
    },
    //获取结算信息
    getPaymentInfo() {
      let params = {
        InvoiceTaskID: this.invoiceTaskID,
        Type: "01", //货主
      };
      getPaymentInfo(params)
        .then((res) => {
          this.waybillForm.address = res.payInfo.SelectedAddress; //已选状态
          this.waybillForm.provider = res.payInfo.SelectedProvide; //已选服务商
          this.waybillForm.invoiceTitle = res.payInfo.InvoiceTile; //已选发票抬头
          this.waybillForm.invoiceType = res.payInfo.InvoiceType; //发票类型
          this.waybillForm.invoiceKind = res.payInfo.InvoiceKind; //普票专票
          this.waybillForm.invoiceKindTec = res.payInfo.InvoiceKindTec; //普票专票
          this.waybillForm.applyDateTime = res.payInfo.ApplyDateTime;
          this.waybillForm.wayBillCount = res.payInfo.WayBillCount;
          this.waybillForm.transFee = res.payInfo.TransFee;
          this.waybillForm.taxes = res.payInfo.Taxes;
          this.waybillForm.totalAmount = res.payInfo.TotalAmount;
          this.waybillForm.TaskType = res.payInfo.TaskType; //业务类型
          this.taskType = res.payInfo.TaskStatus; //获取服务商答复状态
          this.serverDetail.type = res.payInfo.Rate; //发票费率
          this.waybillForm.balance = res.payInfo.Balance; //账户余额
          this.waybillForm.dealAmount = res.payInfo.DealAmount; //可交易金额
          this.waybillForm.routeAmount = res.payInfo.RouteAmount; //在途金额
          this.waybillForm.payType = res.payInfo.PayModeID; //支付方式
          this.waybillForm.invoiceAmoutType = res.payInfo.InvoiceAmoutType; //开票金额
          if (res.payInfo.invoiceKindTec) {
            this.invoiceKindValue = `运费-${this.waybillForm.invoiceKind} 技术服务费-${this.waybillForm.invoiceKindTec}`;
          } else {
            this.invoiceKindValue = this.waybillForm.invoiceKind;
          }
          if (this.taskType == 0) {
            //货主放弃付款服务商同意后
            if (res.payInfo.PayStatus == 3) {
              this.reselect();
            } else {
              //等待
              this.flag.waitDialog = true;
            }
          } else if (this.taskType == 1) {
            //同意
            this.flag.allowDialog = true;
          } else if (this.taskType == 2) {
            //拒绝
            this.flag.rejectDialog = true;
            let params = {
              taskId: this.invoiceTaskID,
            };
            getTaskRejectOpinion(params).then((res) => {
              this.rejectContent = res.data;
              this.rejectName = res.name;
            });
          } else if (this.taskType == 6) {
            //服务商放弃开票后重新选择服务商
            this.reselect();
          }
          //获取结算信息
          let params = {
            code: res.payInfo.InvoiceType,
            invoiceTaskId: this.invoiceTaskID,
          };
          this.billsCount(params);
        })
        .catch((e) => {
          //code=-2表示余额不足
          if (e.code == -2) {
            // this.$emit('stepChange', 1)
          }
        });
    },
    //获取结算信息
    billsCount(params) {
      billsCount(params).then((res) => {
        this.serverDetail.enterpriseFullName =
          res.supplierName.EnterpriseFullName;
        this.serverDetail.userFound = res.userFound;
        this.serverDetail.address = this.waybillForm.address;
        this.serverDetail.waitPay = res.waitPay;
        this.serverDetail.status = res.status;
        this.serverDetail.bossEnterpriseFullName =
          res.consignInfo.EnterpriseFullName;
        this.serverDetail.bossEnterpriseCreditCode =
          res.consignInfo.EnterpriseCreditCode;
        this.serverDetail.bossEnterpriseAddress =
          res.consignInfo.EnterpriseAddress;
        this.serverDetail.bossBankerName = res.consignInfo.BankerName;
        this.serverDetail.bossBankerNumber = res.consignInfo.BankerNumber;
        this.serverDetail.bossLegalPersonlPhone =
          res.consignInfo.LegalPersonlPhone;
        this.serverDetail.payDict = res.payDict;

        this.serverDetail.showOfflineVoucher = res.payDict.includes("线下");
        if (this.serverDetail.showOfflineVoucher) {
          this.$nextTick(function () {
            if (this.$refs.uploadFile) {
              this.$refs.uploadFile.getUploadFile();
              this.showComfirmPay(true);
            }
          });
        }
      });
    },

    // 确认付款按钮是否可选
    showComfirmPay(val) {
      this.flag.isComfirmPay = val;
    },

    //放弃付款
    giveUpPayMent() {
      const params = {
        taskId: this.getInvoiceTaskID,
      };
      giveUpPayMent(params)
        .then((res) => {})
        .finally(() => {
          this.goBack();
        });
    },
    //确认付款
    comfirmPays() {
      this.$confirm("您是否确定对该结算单进行付款？", "确认付款", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        center: true,
      })
        .then(() => {
          if (this.waybillForm.payType == 2 && this.waybillForm.payType == 3) {
            if (
              Number(this.serverDetail.waitPay) >
              Number(this.waybillForm.dealAmount)
            ) {
              this.$message.error("当前待支付金额大于可交易金额");
              return;
            }
          }
          this.flag.comfirmPaying = true;
          let params = {
            code: this.serverDetail.payDict,
            invoiceTaskID: this.invoiceTaskID,
          };
          const loading = this.$loading({
            lock: true,
            text: "付款中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          comfirmPays(params)
            .then((res) => {
              if (res.OwnerPays) {
                this.goBack();
              }
            })
            .catch(() => {
              this.goBack();
            })
            .finally(() => {
              this.flag.comfirmPaying = false;
              loading.close();
            });

          if (this.serverDetail.showOfflineVoucher) {
            this.$refs.uploadFile.preserveVoucher();
          }
        })
        .catch(() => {
          loading.close();
        });
    },
    //是否放弃付款
    handleClickGiveIpPayMent() {
      this.$confirm(
        "你选择放弃付款，则本结算单将终止流程并退回到运单库",
        "放弃付款",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          this.giveUpPayMent();
        })
        .catch();
    },
  },
  computed: {
    ...mapGetters(["getInvoiceTaskID", "getWaybillType"]),
  },
  watch: {
    "flag.showMap"(val) {
      if (!val) {
        this.mapList = [];
      }
    },
  },
  created() {
    this.invoiceTaskID = this.getInvoiceTaskID;
    //获取结算信息
    this.getPaymentInfo();
  },
  components: {
    Step,
    UploadFile,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../assets/style/variable.scss";

/deep/.allow .el-dialog--center .el-dialog__body {
  padding: 0px 20px 10px 50px !important;
}

/deep/.voucherWrap .uploadImg {
  width: 100px !important;
  height: 80px !important;
}

.facilityBox {
  .settleBox {
    margin-bottom: 10px;
    padding: 10px 16px;
    background-color: #f0f0f0;
    color: #666;
  }

  .sumArea {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    p {
      width: 150px;
      margin-right: 10px;
      font-size: 20px;
      font-weight: 700;
    }

    .total {
      background: $primary;
      display: flex;
      flex-wrap: wrap;
      color: white;
      padding: 10px;
      border-radius: 5px;

      .item {
        width: 25%;
      }
    }
  }

  .step {
    margin: 0 auto 16px;
    background-color: #fff;
    padding: 10px;

    .title {
      color: #2081ff;
      position: relative;
      text-indent: 15px;
      font-size: 14px;
      border-bottom: 1px solid $borderGray;
      padding: 8px 0;
      margin-bottom: 20px;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3px;
        height: 14px;
        border-radius: 5px;
        background: $primary;
      }
    }
  }

  .waybillForm {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
  }

  .wait,
  .allow,
  .reject {
    p {
      margin: 10px 0;
      text-align: center;
    }
  }

  .allow-info {
    p {
      width: 40%;
      margin: 5px 0;
      text-align: left;
      display: flex;

      &:nth-child(1) {
        width: 60%;
      }

      .info-title {
        font-weight: bolder;
        width: 22%;
        text-align: right;
        white-space: nowrap;
      }

      .info-content {
        color: #333;
      }

      &:nth-child(2) {
        .info-title {
          width: 40%;
        }
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #f5f5f5;
    margin-top: 8px;
  }

  .funcs {
    text-align: center;
  }
}
</style>
<style scoped>
/* .el-dialog__header {
  border: none !important;
} */
</style>
